import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Camera } from "../camera";
import Webcam from "react-webcam";
import { Root, Preview, Footer, GlobalStyle } from "../styles";
import cv from "@techstark/opencv-js";
import "./styles.css";

export default function CaptureID() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState();
  const webcamRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const outputImgRef = React.useRef(null);
  const [capturedImage, setCapturedImage] = React.useState("");
  const [modelLoaded, setModelLoaded] = React.useState(false);
  const capturedID = React.useRef(false);
  const frameRate = 500;
  let loaded = false;

  let minWidth = 140;
  let minHeight = 100;

  function sorted(items, kwargs = {}) {
    const key = kwargs.key === undefined ? (x) => x : kwargs.key;
    const reverse = kwargs.reverse === undefined ? false : kwargs.reverse;
    const sortKeys = items.map((item, pos) => [key(item), pos]);
    const comparator = Array.isArray(sortKeys[0][0])
      ? (left, right) => {
          for (var n = 0; n < Math.min(left.length, right.length); n++) {
            const vLeft = left[n],
              vRight = right[n];
            const order = vLeft == vRight ? 0 : vLeft > vRight ? 1 : -1;
            if (order != 0) return order;
          }
          return left.length - right.length;
        }
      : (left, right) => {
          const vLeft = left[0],
            vRight = right[0];
          const order = vLeft == vRight ? 0 : vLeft > vRight ? 1 : -1;
          return order;
        };
    sortKeys.sort(comparator);
    if (reverse) sortKeys.reverse();
    return sortKeys.map((order) => items[order[1]]);
  }

  useEffect(() => {
    if (!loaded) {
      setTimeout(2000);
    }
    cv.then(() => {
      let handle;
      const fpsInterval = 1000 / 100;
      let then = Date.now();
      var now, elapsed;
      console.log("capturedID value:", capturedID);

      const drawContours = (edged, c, h) => {
        let cnts = cv.findContours(edged, c, h, cv.RETR_TREE, cv.CHAIN_APPROX_SIMPLE);
        console.log(cnts);
        //        cnts = sorted(cnts, { reverse: true });

        for (var cnt in cnts) {
          let area = cv.contourArea(cnt);
          if (area >= 2000 && area <= 5000) {
            cv.drawContours(edged, [cnt], -1, 255, -1);
          }
          return edged;
        }
      };

      const detectFace = async () => {
        const contoursColor = new cv.Scalar(255, 255, 255);
        const rectangleColor = new cv.Scalar(255, 0, 0);
        let currX;
        let currY, currW, currH;
        let rect;

        const imageSrc = webcamRef.current.getScreenshot();
        if (!imageSrc) return;

        return new Promise((resolve) => {
          imgRef.current.src = imageSrc;
          imgRef.current.onload = () => {
            try {
              const contoursColor = new cv.Scalar(255, 0, 0);
              let img = cv.imread(imgRef.current);
              let dst = new cv.Mat();
              let M = cv.Mat.ones(5, 5, cv.CV_8U);
              let anchor = new cv.Point(-1, -1);
              let ksize = new cv.Size(3, 3);
              //              console.log(img);
              //              let dst = cv.Mat.zeros(img.rows, img.cols, cv.CV_8UC3);

              cv.cvtColor(img, img, cv.COLOR_RGBA2GRAY, 0);
              //              cv.threshold(img, img, 177, 200, cv.THRESH_BINARY);
              cv.Canny(img, img, 0, 70, 3, false);
              cv.GaussianBlur(img, img, ksize, cv.BORDER_DEFAULT);
              cv.dilate(img, img, M, anchor, 1, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
              let contours = new cv.MatVector();
              let hierarchy = new cv.Mat();
              let poly = new cv.MatVector();
              cv.imshow("outputImage", img);
              cv.findContours(img, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

              console.log(contours.size());

              let cntAreas = [];
              let topMaskRect = document.querySelector("#topMask").getBoundingClientRect();
              let targetX = 10;
              let targetY = topMaskRect.height * 0.5;

              for (let i = 0; i < contours.size(); ++i) {
                let tmp = new cv.Mat();
                let cnt = contours.get(i);
                let rect = cv.boundingRect(cnt);
                let area = cv.contourArea(cnt, false);
                console.log(area);
                if (area >= 6500 && area <= 20000) {
                  cntAreas.push({ area: area, x: rect.x, y: rect.y });
                  cv.drawContours(dst, contours, 0, contoursColor, 1, 8, hierarchy, 100);
                }
                poly.push_back(tmp);
                cnt.delete();
                tmp.delete();
              }

              cntAreas.sort(function (a, b) {
                return a - b;
              });

              if (cntAreas[0] !== undefined && cntAreas[0].area !== undefined) {
                let diffX = Math.abs(cntAreas[0].x - targetX);
                let diffY = Math.abs(cntAreas[0].y - targetY);
                console.log("currentY: " + cntAreas[0].y + ", targetY: " + targetY + ", diffX: " + diffX + ", diffY: " + diffY + ", area: " + cntAreas[0].area);
              }
              if (cntAreas[0] !== undefined && cntAreas[0].area !== undefined && cntAreas[0].area && cntAreas[0].area > 10000) {
                let diffX = Math.abs(cntAreas[0].x - targetX);
                let diffY = Math.abs(cntAreas[0].y - targetY);
                console.log("currentY: " + cntAreas[0].y + ", targetY: " + targetY + ", diffX: " + diffX + ", diffY: " + diffY + ", area: " + cntAreas[0].area);

                if (diffX < 20 && diffY < 20) {
                  document.querySelector(".topMask").style.borderBottomColor = "green";
                  document.querySelector(".bottomMask").style.borderTopColor = "green";
                  document.querySelector("#webcam").style.display = "none";
                  document.querySelector("#capturedImage").style.display = "inline";
                  capturedID.current = true;
                  cv.imshow("outputImage", img);
                  //                document.querySelector("#retakeBtn").style.display = "inline";
                  cancelAnimationFrame(handle);
                  setCapturedImage(imageSrc);
                  document.querySelector("#scanProgress").style.background = "#1db954";
                  document.querySelector("#scanProgress").innerHTML = "Saving...";

                  async function waiter() {
                    await new Promise((r) => setTimeout(r, 3000));
                    alert("GO");
                  }
                  waiter();
                }
              } else {
                document.querySelector(".topMask").style.borderBottomColor = "#aa0000";
                document.querySelector(".bottomMask").style.borderTopColor = "#aa0000";
              }
              //              cv.imshow("outputImage", img);

              //              cnts = cv.findContours(edged, contours, hierarchy, cv.RETR_TREE, cv.CHAIN_APPROX_SIMPLE);
              //            cnts = sorted(cnts, { reverse: true });

              //              cv.imshow("outputImage", img);

              /*
              edged = cv.dilate(edged, null, 1);

              edged = drawContours(edged);

              let cnts;
              let hierarchy = cv.findContours(edged, cv.RETR_TREE, cv.CHAIN_APPROX_SIMPLE);
              cnts = sorted(cnts, { reverse: true });

              if (currW < minWidth || currH < minHeight) {
                document.querySelector(".outputOverlay").style.borderColor = "red";
                return;
              } else {
                console.log("GOOD!", currX);
                document.querySelector(".outputOverlay").style.borderColor = "green";
                document.querySelector("#webcam").style.display = "none";
                document.querySelector("#capturedImage").style.display = "inline";
                capturedID.current = true;
                document.querySelector("#retakeBtn").style.display = "inline";
                cancelAnimationFrame(handle);
                setCapturedImage(imageSrc);

                cv.drawContours(dst, contours, 0, contoursColor, 1, 8, hierarchy, 100);
                let point1 = new cv.Point(currX, currY);
                let point2 = new cv.Point(currX + currW, currY + currH);
                cv.rectangle(dst, point1, point2, rectangleColor, 2, cv.LINE_AA, 0);
                cv.imshow("outputImage", dst);
                img.delete();
                dst.delete();
                contours.delete();
                hierarchy.delete();
              }
              */
              resolve();
            } catch (error) {
              console.log(error);
              resolve();
            }
          };
        });
      };

      const nextTick = () => {
        if (!capturedID.current) {
          handle = requestAnimationFrame(async () => {
            await detectFace();
            await new Promise((r) => setTimeout(r, frameRate));
            nextTick();
          });
        }
      };
      nextTick();
      return () => {
        cancelAnimationFrame(handle);
      };
    });
  }, []);

  return (
    <Fragment>
      <Root>
        <div className="topBar">
          <div id="scanProgress" className="topLabel">
            Scanning ID...
          </div>
        </div>
        <div className="bottomBar">
          <img src="./assets/img/vectorAA_logo_1.png" className="logo" />
        </div>
        <div className="topMask" id="topMask"></div>
        <div className="bottomMask"></div>
        <Webcam videoConstraints={{ facingMode: "environment" }} ref={webcamRef} id="webcam" className="webcam" screenshotFormat="image/jpeg" />
        <img src={capturedImage} alt="captured" id="capturedImage" />
        <div>
          <div className="outputOverlay"> </div>
          <img className="inputImage" alt="input" ref={imgRef} />
          <canvas id="outputImage" className="outputImage" ref={outputImgRef} />
        </div>
        <span id="info">info</span>
      </Root>
      <GlobalStyle />
    </Fragment>
  );
}
