import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import VectorVerify from "./components/VectorVerify/VectorVerify";
import CaptureID from "./components/VectorVerify/CaptureID_OpenCV";
import CaptureFace from "./components/VectorVerify/CaptureFace";
import Header from "./components/Common/Header";
import Footer from "./components/Common/Footer";
import history from "./utils/history";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./components/VectorVerify/Landing";

const onRedirectCallback = (appState) => {
  console.log(appState);
  console.log("REDIRECT CALLBACK... this is where we choose where to go.");
};

const rootElement = document.getElementById("root");

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      textTransform: "none",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <div className="wrapper">
        <Header />
        <Routes>
          <Route path="/" element={<VectorVerify />}>
            <Route path="/landing" element={<Landing />} />
            <Route path="/capture_id" element={<CaptureID />} />
            <Route path="/capture_face" element={<CaptureFace />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  </ThemeProvider>,
  rootElement
);
