// React
import { React, useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Camera } from "../camera";
import "./styles.css";
// CSS

// Sub-components

//import { ErrorBoundary } from "react-error-boundary";
let softPhoneScript;

export default function VectorVerify() {
  const location = useLocation();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState();
  if (location.pathname == "/") {
    console.log("SEND IT");
    window.location.href = "/capture_id";
  }

  return (
    <div id="main-content">
      <Outlet />
    </div>
  );
}
